import { graphql } from '@/api/gql'

export const createSession = graphql(`
  mutation createSession($email: String!, $password: String!) {
    data: createSession(input: { username: $email, password: $password, omniauthRequest: true }) {
      session {
        token
        user {
          uuid
        }
        omniauthCallback
      }
      error {
        message
      }
    }
  }
`)
